.badge {
  height: fit-content;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badge--standard {
  background-color: $yellow-300;
  color: $black;
}

.badge--inactive {
  background-color: $gray-100;
  color: $gray-base;
}

.badge--blocked {
  background-color: $red;
  color: $gray-100;
}
